import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable()
export class AppConfigService {
   private config: any = null;
   private env: any = null;
   private httpClient: HttpClient;

   public username = "UNKNOWN";

   constructor(
      httpClient: HttpClient
   ) {
      this.httpClient = httpClient;
   }

   public async Initialize(): Promise<any> {
      let url = "./assets/env.json";
      const data = await this.httpClient.get<string>(url).toPromise();
      this.env = data["env"];
      if (this.getEnvironment() == "$(Environment)") {
         this.env = "development";
      }
      url = "./assets/env." + this.env + ".json";
      this.config = await this.httpClient.get<string>(url).toPromise();
   }

   public getApiUrl() {
      return this.config["apiUrl"];
   }

   //Environment which come from assets/env.json <- $(Environment)
   public getEnvironment() {
      return this.env;
   }

   public getMail() {
      return this.config["mail"];
   }

   public getMessageErrorLive() {
      return this.config["messageErrorLive"];
   }

   public getMessageSuccessLive() {
      return this.config["messageSuccessLive"];
   }

   public getSiteName() {
      return this.config["site"];
   }

   // Error handling
   public handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }
}