import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Hammer from 'hammerjs';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';

import { LogService } from 'src/sites/core/services/log.service';
import { MatPaginatorI18nService } from 'src/sites/core/services/matPaginator.service';
import { FooterComponent } from '../app/common/footer/footer.component';
import { NavbarComponent } from '../app/common/navbar/navbar.component';
import { SidebarComponent } from '../app/common/sidebar/sidebar.component';
import { AdminUserListComponent } from '../sites/core/components/adminUser/adminUser-list.component';
// used to create fake backend
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/environments/environment';
import { AccessRightsService } from 'src/sites/core/services/accessRights.service';
import { LastUpdatesService } from 'src/sites/core/services/lastUpdates.service';
import { UserService } from 'src/sites/core/services/user.service';
import { WorkgroupService } from 'src/sites/core/services/workgroup.service';
import { ActivityComponent } from 'src/sites/vabourlettis/components/activity/activity.component';
import { AdminActivityListComponent } from 'src/sites/vabourlettis/components/adminActivity/adminActivity-list.component';
import { AdminBuildingTrainingListComponent } from 'src/sites/vabourlettis/components/adminBuildingTraining/adminBuildingTraining-list.component';
import { AdminChampionshipListComponent } from 'src/sites/vabourlettis/components/adminChampionship/adminChampionship-list.component';
import { AdminDevelopmentComponent } from 'src/sites/vabourlettis/components/adminDevelopment/adminDevelopment.component';
import { AdminMatchListComponent } from 'src/sites/vabourlettis/components/adminMatch/adminMatch-list.component';
import { AdminSponsorListComponent } from 'src/sites/vabourlettis/components/adminSponsor/adminSponsor-list.component';
import { AdminTeamListComponent } from 'src/sites/vabourlettis/components/adminTeam/adminTeam-list.component';
import { BuildingTrainingComponent } from 'src/sites/vabourlettis/components/buildingTraining/buildingTraining.component';
import { ClubComponent } from 'src/sites/vabourlettis/components/club/club.component';
import { ComiteeMeetingComponent } from 'src/sites/vabourlettis/components/comiteeMeeting/comiteeMeeting.component';
import { ComiteeMemberComponent } from 'src/sites/vabourlettis/components/comiteeMember/comiteeMember.component';
import { ComiteeTodoComponent } from 'src/sites/vabourlettis/components/comiteeTodo/comiteeTodo.component';
import { ContactusComponent } from 'src/sites/vabourlettis/components/contactus/contactus.component';
import { FinancesComponent } from 'src/sites/vabourlettis/components/finances/finances.component';
import { MatchComponent } from 'src/sites/vabourlettis/components/match/match.component';
import { NewsPaperComponent } from 'src/sites/vabourlettis/components/newsPaper/newsPaper.component';
import { ProfileComponent } from 'src/sites/vabourlettis/components/profile/profile.component';
import { RankingComponent } from 'src/sites/vabourlettis/components/ranking/ranking.component';
import { SponsorComponent } from 'src/sites/vabourlettis/components/sponsor/sponsor.component';
import { TeamComponent } from 'src/sites/vabourlettis/components/team/team.component';
import { TrainingInfoComponent } from 'src/sites/vabourlettis/components/trainingInfo/trainingInfo.component';
import { TrainingPlayerComponent } from 'src/sites/vabourlettis/components/trainingPlayer/trainingPlayer.component';
import { ActivityService } from 'src/sites/vabourlettis/services/activity.service';
import { ActivityByMemberService } from 'src/sites/vabourlettis/services/activityByMember.service';
import { BuildingTrainingService } from 'src/sites/vabourlettis/services/buildingTraining.service';
import { CacheService } from 'src/sites/vabourlettis/services/cache.service';
import { ChampionshipService } from 'src/sites/vabourlettis/services/championship.service';
import { ComiteeMeetingService } from 'src/sites/vabourlettis/services/comiteeMeeting.service';
import { FormService } from 'src/sites/vabourlettis/services/form.service';
import { MatchService } from 'src/sites/vabourlettis/services/match.service';
import { SettingService } from 'src/sites/vabourlettis/services/setting.service';
import { SponsorService } from 'src/sites/vabourlettis/services/sponsor.service';
import { TeamService } from 'src/sites/vabourlettis/services/team.service';
import { TrainingPlayerService } from 'src/sites/vabourlettis/services/trainingPlayer.service';
import { ErrorInterceptor, JwtInterceptor } from "../sites/core/helpers";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomNativeDateAdapter } from './common/directives/nativeDateAdapter';
import { SafePipe } from './common/directives/safeHtml.directive';
//import { SharedModule } from './share.module';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListItem, MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AlertShowComponent } from 'src/sites/core/components/alertshow/alertshow.component';
import { ChangePasswordComponent } from 'src/sites/core/components/account/changePassword.component';
import { LoginComponent } from 'src/sites/core/components/account/login.component';
import { PasswordStrengthComponent } from 'src/sites/core/components/account/passwordStrength.component';
import { RecoverPasswordComponent } from 'src/sites/core/components/account/recoverPassword.component';
import { RegisterComponent } from 'src/sites/core/components/account/register.component';
import { ConfirmDialogComponent } from 'src/sites/core/components/confirmDialog/confirmDialog.component';
import { DatagridComponent } from 'src/sites/core/components/datagrid/datagrid.component';
import { ModalFormComponent } from 'src/sites/core/components/form/modalForm.component';
import { ImageCropComponent } from 'src/sites/core/components/imageCrop/imageCrop.component';
import { ImageCropMainComponent } from 'src/sites/core/components/imageCrop/imageCropMain.component';
import { PwaComponent } from 'src/sites/core/components/pwa/pwa.component';
import { PwaService } from 'src/sites/core/services/pwa.service';
import { SystemService } from 'src/sites/core/services/system.service';
import { AuthenticationService } from 'src/sites/vabourlettis/authentication/authentication.service';
import { StorageService } from 'src/sites/vabourlettis/authentication/storage.service';
import { AdminHomeNewsListComponent } from 'src/sites/vabourlettis/components/adminHomeNews/adminHomeNews-list.component';
import { AdminImportDataComponent } from 'src/sites/vabourlettis/components/adminImportData/adminImportData.component';
import { DiaryComponent } from 'src/sites/vabourlettis/components/diary/diary.component';
import { HomeComponent } from 'src/sites/vabourlettis/components/home/home.component';
import { AppointmentService } from 'src/sites/vabourlettis/services/appointmentService';
import { BuildingTrainingClosingDayService } from 'src/sites/vabourlettis/services/buildingTrainingClosingDay.service';
import { HomeNewsService } from 'src/sites/vabourlettis/services/homeNews.service';
import { RankingService } from 'src/sites/vabourlettis/services/ranking.service';
import { SportsClubService } from 'src/sites/vabourlettis/services/sportsClub.service';
import { TodoService } from 'src/sites/vabourlettis/services/todoService';
import { VersionningService } from 'src/sites/vabourlettis/services/versionningService';
import { VersionDialogComponent } from './common/footer/versionDialog.component';
import { MemberComponent } from 'src/sites/vabourlettis/components/member/member.component';
import { AgmCoreModule } from '@agm/core';
import { AppConfigService } from 'src/sites/core/services/appconfig.service';
import { PrintComponent } from './common/print/print.component';
import { PrintService } from 'src/sites/core/services/print.service';
import { ButtonComponent } from './common/button/button.component';
import { ButtonMenuComponent } from './common/button/buttonMenu.component';
import { TimelineDetailComponent } from 'src/sites/core/components/timeline/timelineDetail.component';
import { MatchDetailComponent } from 'src/sites/core/components/match/matchDetail.component';
import { SupperEventComponent } from 'src/sites/core/components/supperEvent/supperEvent.component';
import { ButtonPlusMinusComponent } from './common/button/buttonPlusMinus.component';
import { GDPRDialogComponent } from './common/footer/gDPRDialog.component';
import { GDPRService } from 'src/sites/vabourlettis/services/gDPRService';
import { MatchCalendarComponent } from 'src/sites/core/components/matchCalendar/matchCalendar.component';
import { MatchStatusComponent } from 'src/sites/core/components/matchStatus/matchStatus.component';
import { TrainingPlayerEditionComponent } from 'src/sites/vabourlettis/components/trainingPlayerEdition/trainingPlayerEdition.component';
import { TrainingPlayersManagerComponent } from 'src/sites/vabourlettis/components/trainingPlayersManager/trainingPlayersManager.component';
import { TrainingPlayersStatisticsComponent } from 'src/sites/vabourlettis/components/trainingPlayersStatistics/trainingPlayersStatistics.component';
import localeFr from '@angular/common/locales/fr';
import { OkDialogComponent } from 'src/sites/core/components/okDialog/okDialog.component';
import { TooltipComponent } from './common/tooltip/tooltip.component';
import { ComiteeMeetingEditionComponent } from 'src/sites/vabourlettis/components/comiteeMeetingEdition/comiteeMeetingEdition.component';
import { ComiteeMeetingCalendarComponent } from 'src/sites/core/components/comiteeMeetingCalendar/comiteeMeetingCalendar.component';
import { BlobService } from 'src/sites/vabourlettis/services/blob.service';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'
import { DataPrivacyDialogComponent } from './common/footer/dataPrivacyDialog.component';
import { DataPrivacyService } from 'src/sites/vabourlettis/services/dataPrivacyService';
import { RedirectGuard } from './redirectGuard';
import { EncryptionService } from 'src/sites/core/services/encryption.service';
import { AdminBuildingTrainingClosingDayListComponent } from 'src/sites/vabourlettis/components/adminBuildingTrainingClosingDay/adminBuildingTrainingClosingDay-list.component';
import { SignalRService } from 'src/sites/core/services/signalR.service';
import { SubjectService } from 'src/sites/core/services/subject.service';
import { PanelComponent } from './common/panel/panel.component';
import { ExpandedPanelComponent } from './common/expandedPanel/expandedPanel.component';
import { ButtonHRefComponent } from './common/button/buttonHRef.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MailService } from 'src/sites/core/services/mail.service';
import { NotificationService } from 'src/sites/core/services/notification.service';
import { AdminMemberListComponent } from 'src/sites/vabourlettis/components/adminMember/adminMember-list.component';
import { SnackbarService } from 'src/sites/core/services/snackbar.service';
import { MessagePopupComponent } from 'src/sites/core/components/messagePopup/messagePopup.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
   overrides = <any>{
      swipe: { direction: Hammer.DIRECTION_ALL },
   };
}

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

export function InitApp(appConfigService: AppConfigService) {
   return () => appConfigService.Initialize();
 }

registerLocaleData(localeFr, 'fr');

@NgModule({
   declarations: [
      ActivityComponent,
      AdminActivityListComponent,
      AdminBuildingTrainingListComponent,
      AdminBuildingTrainingClosingDayListComponent,
      AdminChampionshipListComponent,
      AdminDevelopmentComponent,
      AdminHomeNewsListComponent,
      AdminImportDataComponent,
      AdminMatchListComponent,
      AdminSponsorListComponent,
      AdminTeamListComponent,
      AdminMemberListComponent,
      AdminUserListComponent,
      AlertShowComponent,
      AppComponent,
      BuildingTrainingComponent,
      ButtonComponent,
      ButtonHRefComponent,
      ButtonMenuComponent,
      ButtonPlusMinusComponent,
      ChangePasswordComponent,
      ClubComponent,
      ComiteeMeetingComponent,
      ComiteeMeetingCalendarComponent,
      ComiteeMeetingEditionComponent,
      ComiteeMemberComponent,
      ComiteeTodoComponent,
      ConfirmDialogComponent,
      OkDialogComponent,
      ContactusComponent,
      DatagridComponent,
      DataPrivacyDialogComponent,
      DiaryComponent,
      FinancesComponent,
      FooterComponent,
      GDPRDialogComponent,
      HomeComponent,
      ImageCropComponent,
      ImageCropMainComponent,
      LoginComponent,
      MatchComponent,
      MatchCalendarComponent,
      MatchDetailComponent,
      MatchStatusComponent,
      ModalFormComponent,
      NavbarComponent,
      NewsPaperComponent,
      PanelComponent,
      ExpandedPanelComponent,
      PasswordStrengthComponent,
      PrintComponent,
      ProfileComponent,
      MemberComponent,
      MessagePopupComponent,
      PwaComponent,
      RankingComponent,
      RecoverPasswordComponent,
      RegisterComponent,
      SafePipe,
      SidebarComponent,
      SponsorComponent,
      SupperEventComponent,
      TeamComponent,
      TimelineDetailComponent,
      TooltipComponent,
      TrainingInfoComponent,
      TrainingPlayerComponent,
      TrainingPlayerEditionComponent,
      TrainingPlayersManagerComponent,
      TrainingPlayersStatisticsComponent,
      VersionDialogComponent
   ],
   imports: [
      AgmCoreModule.forRoot({
         apiKey: 'AIzaSyAaSBjVjolei-VxiJd8diAEv2npxBjnIvw'
       }),
      AppRoutingModule,
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      BrowserAnimationsModule,
      ChartsModule,
      ClipboardModule,
      CommonModule,
      FormsModule,
      HttpClientModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatDialogModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatSlideToggleModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatOptionModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatSelectModule,
      MatSnackBarModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      NgbModule,
      NgxMaskModule.forRoot({ showMaskTyped: true }),
      NgxMaterialTimepickerModule,
      NgxExtendedPdfViewerModule,
      ReactiveFormsModule,
      ServiceWorkerModule.register('custom-ngsw-worker.js', {
         enabled: environment.production,
         registrationStrategy: 'registerWhenStable:15000',
       }),
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
         }
      }),
   ],
   providers: [
      { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false} },
      { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
      { provide: APP_INITIALIZER, useFactory: InitApp, deps: [AppConfigService], multi: true },
      { provide: LOCALE_ID, useValue: 'fr'},
      { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
      { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
      { provide: MAT_DIALOG_DATA, useValue: {} },
      { provide: MatDialogRef, useValue: {} },
      { provide: DateAdapter, useClass: CustomNativeDateAdapter },
      AccessRightsService,
      ActivityByMemberService,
      ActivityService,
      AppointmentService,
      AuthenticationService,
      BlobService,
      BuildingTrainingClosingDayService,
      BuildingTrainingService,
      CacheService,
      ChampionshipService,
      ComiteeMeetingService,
      DatePipe,
      DataPrivacyService,
      EncryptionService,
      FormService,
      HomeNewsService,
      LastUpdatesService,
      LogService,
      MailService,
      MatchService,
      NotificationService,
      RankingService,
      SettingService,
      SignalRService,
      SnackbarService,
      SponsorService,
      SportsClubService,
      SubjectService,
      SystemService,
      TeamService,
      ThemeService,
      TodoService,
      StorageService,
      TrainingPlayerService,
      UserService,
      VersionningService,
      WorkgroupService,
      AppConfigService,
      PrintService,
      GDPRService,
      RedirectGuard
   ],
   exports: [
      ActivityComponent,
      AdminActivityListComponent,
      AdminBuildingTrainingListComponent,
      AdminBuildingTrainingClosingDayListComponent,
      AdminChampionshipListComponent,
      AdminDevelopmentComponent,
      AdminHomeNewsListComponent,
      AdminImportDataComponent,
      AdminMatchListComponent,
      AdminSponsorListComponent,
      AdminTeamListComponent,
      AdminMemberListComponent,
      AdminUserListComponent,
      AlertShowComponent,
      AppComponent,
      BuildingTrainingComponent,
      ButtonComponent,
      ButtonHRefComponent,
      ButtonMenuComponent,
      ButtonPlusMinusComponent,
      ChangePasswordComponent,
      ClubComponent,
      ComiteeMeetingComponent,
      ComiteeMeetingCalendarComponent,
      ComiteeMeetingEditionComponent,
      ComiteeMemberComponent,
      ComiteeTodoComponent,
      ConfirmDialogComponent,
      OkDialogComponent,
      ContactusComponent,
      DatagridComponent,
      DataPrivacyDialogComponent,
      DiaryComponent,
      FinancesComponent,
      FooterComponent,
      GDPRDialogComponent,
      HomeComponent,
      ImageCropComponent,
      ImageCropMainComponent,
      LoginComponent,
      MatchComponent,
      MatchCalendarComponent,
      MatchDetailComponent,
      MatchStatusComponent,
      ModalFormComponent,
      NavbarComponent,
      NewsPaperComponent,
      PanelComponent,
      ExpandedPanelComponent,
      PasswordStrengthComponent,
      PrintComponent,
      ProfileComponent,
      MemberComponent,
      PwaComponent,
      RankingComponent,
      RecoverPasswordComponent,
      RegisterComponent,
      SafePipe,
      SidebarComponent,
      SponsorComponent,
      SupperEventComponent,
      TeamComponent,
      TimelineDetailComponent,
      TooltipComponent,
      TrainingInfoComponent,
      TrainingPlayerComponent,
      TrainingPlayerEditionComponent,
      TrainingPlayersManagerComponent,
      TrainingPlayersStatisticsComponent,
      VersionDialogComponent
   ],
   bootstrap: [AppComponent]
})

export class AppModule {
}

export function HttpLoaderFactory(httpClient: HttpClient) {
   return new MultiTranslateHttpLoader(httpClient, [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './sites/vabourlettis/appsettings/i18n/', suffix: '.json' }
   ]);
}

